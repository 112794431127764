@import "typefaces.scss";
@import "colours.scss";
@import "theme.scss";

* {
  outline: none;
}

html body {
  background-color: $background;
  font-family: $proxima-nova;
}

html {
  height: 100vh;
  width: 100%;
  margin: 0%;
  padding: 0%;
}

body {
  height: 100vh;
  width: 100%;
  margin: 0;
}

hr {
  width: 100%;
  margin: 0;

  border: 1px solid $light-grey;
  box-sizing: border-box;

  &.alt {
    width: 100%;
    margin-bottom: 2rem;

    border: 1px solid $dark-blue;
  }
}

#page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  margin: auto;

  background-color: white;
}

button {
  padding: 0;
  border: none;
  cursor: pointer;
}

a {
  color: unset;
  text-decoration: unset;
}

.error {
  color: $negative-dark;
}

.warning {
  color: $yellow;
}

.positive {
  color: $confirmation-dark;
}

app-modal {
  display: none;
}

app-filter-modal {
  display: none;
}

app-validation-modal {
  display: none;
}

app-provide-info-modal {
  display: none;
}

.cdk-overlay-container {
  z-index: 10001 !important; /* Ensure this is higher than the modal's z-index */
}

.mat-select-panel {
  z-index: 10001 !important; /* Ensure this is higher than the modal's z-index */
}

// Custom scrollbar
/* Chrome, Safari, Edge, Opera */
/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $light-grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark-grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// // Angular material slider
mat-slider {
  min-width: 320px;
}

// Angular material expansion panel
.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

// Angular material expansion panel
.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  border-bottom: 1px solid $light-grey;
}

.mat-expansion-panel-header {
  background-color: $grey-background;
}

.mat-expansion-panel-spacing {
  margin: 0;
}

.mat-expansion-panel:not(:last-child) {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

// Angular material button
.mat-button-wrapper {
  font-weight: normal;
  font-family: $proxima-nova;
}

// Global styles
.online {
  color: $green;
}

.offline {
  color: $negative-dark;
}
