/**
 * Project base colours
 */

// Primary colours
$dark-blue: #131f3a;
$blue: #0c7bb4;
$green: #5cba9a;
$black: #000000;
$grey-background: #f9f9f9;

// Secondary colours
$white: #ffffff;
$light-blue: #b4dfff;
$dark-grey: #6a707e;
$light-grey: #e3e5e5;
$background: #f6fcff;
$grey-background: #f9f9f9;
$yellow: #e4ad1e;

// Alerts
$negative-dark: #8d0000;
$negative-light: #ffd9d9;
$confirmation-dark: #5cba9a;
$confirmation-light: #c0ffd2;
$error: #ed4337;

// Tints
$blue-tint: #d2edfb;
$dark-blue-tint: #94a0a6;
$green-tint: #cff0e5;
$negative-tint: #f5c0c0;

// Data colours
$data-colour-1: #6ecf9c;
$data-colour-1: #6c73c1;
$data-colour-1: #ce517e;
$data-colour-1: #37494a;
$data-colour-1: #d2493a;
$data-colour-1: #be4fc1;
$data-colour-1: #cb9f50;
$data-colour-1: #7fce4c;
$data-colour-1: #9cadb7;
$data-colour-1: #496a35;
$data-colour-1: #6a3a2b;
$data-colour-1: #553056;

// Extra chip colours
$green-2: #b7f2d3;
$blue-2: #b3b7e7;
$red-2: #f4b7cd;
$blue-3: #c3eff1;
$red-3: #fdc8c2;
$purple-2: #f2d2f3;
$yellow-2: #f6d69c;

/**
 * Mix-ins
 */

@mixin gradient($attribute) {
  #{$attribute}: -webkit-linear-gradient(to right, $blue, $green);
  #{$attribute}: -moz-linear-gradient(to right, $blue, $green);
  #{$attribute}: -ms-linear-gradient(to right, $blue, $green);
  #{$attribute}: -o-linear-gradient(to right, $blue, $green);
  #{$attribute}: linear-gradient(0.25turn, $blue, $green);
}
