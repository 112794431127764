@import "./colours.scss";
@import url("https://use.typekit.net/plg8dpp.css");

$proxima-nova: "proxima-nova", sans-serif;

/**
* Set project base font size so that 1rem = 10px
* Keep default body size at 16px
*/
html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 22px;
}

h1 {
  font-family: $proxima-nova;
  font-weight: bold;
  font-size: 9.6rem;
  margin: unset;
  text-align: center;
}

h2 {
  font-family: $proxima-nova;
  font-weight: bold;
  font-size: 6rem;
  margin: unset;
  text-align: center;
}

h3 {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 4.8rem;
  margin: unset;
  text-align: center;
}

h4 {
  font-family: $proxima-nova;
  font-weight: bold;
  font-size: 3.2rem;
  margin: unset;
  text-align: center;
}

h5,
.radio-label {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3.1rem;
  margin: unset;
  text-align: center;
  color: $dark-grey;
}

.radio-label-left {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3.1rem;
  margin: unset;
  color: $dark-blue;
  text-align: left;
}

h6 {
  font-family: $proxima-nova;
  font-weight: bold;
  font-size: 2rem;
  margin: unset;
  text-align: center;
}

.subtitle1 {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 1.8rem;
}

.subtitle2,
thead {
  font-family: $proxima-nova;
  font-weight: bold;
  font-size: 1.6rem;
}

.body1,
a {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 22px;
}

a:hover {
  text-decoration: underline;
}

a:active,
a:focus {
  color: $dark-grey;
  text-decoration: underline;
}

a:disabled {
  color: $light-grey;
}

.body2 {
  font-family: $proxima-nova;
  font-weight: normal;
  line-height: normal;
  font-size: 1.2rem;
}

.body3 {
  font-family: $proxima-nova;
  font-weight: lighter;
  font-size: 1rem;
}

.button-large {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.button-text {
  font-family: $proxima-nova;
  font-weight: normal;
  font-size: 1.2rem;
}
